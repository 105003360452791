import { useEffect, useRef } from "react"
import { useLocation, useSearchParams } from "react-router-dom"

import { setAccessToken } from "../redux/reducers/appSlice"
import { useAppDispatch } from "../redux/hooks"

import Introduction from "../components/Introduction"
import WhySummarie from "../components/WhySummarie"
import YouTube from "../components/YouTube"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Plans from "../components/Plans"

const Home = () => {
    const [searchParams] = useSearchParams()
    const { hash } = useLocation()

    const plansRef = useRef<HTMLDivElement>(null)

    const dispatch = useAppDispatch()

    useEffect(() => {
        const [fragment] = hash.split('?');

        const tempToken = searchParams?.get('tempToken');

        if (fragment === '#upgrade') {
            setTimeout(() => {
                plansRef.current?.scrollIntoView({ behavior: 'smooth' })
            }, 100)
        }

        if (tempToken) {
            dispatch(setAccessToken(tempToken))
            localStorage.setItem('tempToken', tempToken)
        } else if (localStorage.getItem('tempToken')) {
            dispatch(setAccessToken(localStorage.getItem('tempToken') as string))
        }
    }, [hash, dispatch]);

    return (
        <div className="home-wrapper">
            <Header />
            <Introduction />
            <YouTube />
            <WhySummarie />
            <Plans ref={plansRef} />
            <Footer />
        </div>
    )
}

export default Home